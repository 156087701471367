import React from "react"
import Navigation from "./ui/Navigation"

import './reset.css'
import './layout.css'
import Footer from "./sections/Footer"

function Layout({ children }) {

  return <>
    <Navigation />
      {children}
    <Footer />
  </>
}

export default Layout
