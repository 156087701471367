import React from 'react'
import styled from 'styled-components'
import Link from '../ui/Link'
import facebook from '../../images/facebook.svg'
import instagram from '../../images/instagram.svg'
import linkedin from '../../images/linkedin.svg'
import phone from '../../images/phone.svg'
import chat from '../../images/chat.svg'
import Container from '../ui/Container'
import Separator from '../ui/Separator'

const Menu = styled.ul`
    margin: 50px auto 0;
`
const MenuItem = styled.li`
    & a {
        text-align: center;
        text-decoration: none;
        color: #fff;
        margin: 20px;
        display: block;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.242px;

        & :first-child {
            margin-top: 0;
        }
        &:active, &:hover {
            color: #D2EFF6;
        }
    }
`
const Social = styled.div`
    max-width: 120px;
    margin: 0 auto;
`
const SocialLinks = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto 0;
`
const SocialLink = styled.li`
    width: 30px;
    height: 30px;
    & img {
        width: 100%;
    }
    a {
        cursor: pointer;
        &:hover {
            opacity: .5;
        }
    }
`
const SocialTitle = styled.h4`
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
`
const DistressLines = styled.div`

    color: #fff;
    font-size: 14px;
    line-height: 35px;
    letter-spacing: -0.242px;
    text-align: center;
    margin: 40px auto 0;

    & > p  {
        max-width: 300px;
        margin: 0 auto;
    }
`
const DistressLine = styled.li`
    margin: 30px auto 0;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        height: 30px;
        width: 30px;
        top: -5px;
        left: -10px;
        background-size: cover;
        background-repeat:  no-repeat;
    }

    &:nth-child(2) {

        padding-left: 40px;

        &::before {
            background-image: url(${phone});
        }
    }

    &:nth-child(3) {

        padding-left: 40px;

        &::before {
            left: 5px;
            background-image: url(${chat});
        }
    }
`
const DistressText = styled.div`
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
`
const DistressDesctiption = styled.i`
    font-style: italic;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.242px;
    a {
        color: #fff;
        text-decoration: none;
    }
`
const DistresLineList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const LegitScriptFooter = styled.div`
    margin: 40px auto 0;
    align-items: center;
    justify-content: center;
    display: flex;
`

const LegitScriptSocial = styled.div`
    display: none;
    margin: 40px auto 0;
    width: 80%;
    max-width: 172px;
    align-items: center;
    justify-content: center;
`

const Copy = styled.div`
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.242px;
    margin: 50px auto 0;
`

const Rights = styled.p`
`

const Terms = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0;

    & > li {
        margin: 0 10px;
    }
`

const TermsLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

const Wrapper = styled.div`
    background: #85ABEE;
    padding: 50px 0;

    @media(min-width: 600px) {
        ${DistressLines} {
            width: 60%;
        }
    }

    @media(min-width: 834px) {
        padding: 100px 0;

        & ${Container} {
            display: flex;
            flex-wrap: wrap;

            & ${Menu} {
                order: 0;
                width: 15%;
                margin-top: 0;

                & ${MenuItem} {
                    & a {
                        margin: 40px 0;
                        text-align: left;
                        font-size: 22px;
                        line-height: 27px;
                    }

                    & :first-child a{
                        margin-top: 0;
                    }
                }
            }

            & ${DistressLines} {
                order: 1;
                flex: 1;
                text-align: left;
                margin-top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;

                & > p {
                    max-width: 100%;
                    text-align: center;
                }

                & ${DistresLineList} {
                    text-align: left;
                    align-items: flex-start;
                    margin-top: 0px;
                    
                    & ${DistressLine} {
                        margin: 30px 0 0 0;

                        &:nth-child(2), &:nth-child(3) {
                            padding-left: 80px;
                        }

                        &:nth-child(2)::before, &:nth-child(3)::before  {
                            width: 50px;
                            height: 50px;
                            left: 0;
                            top: -2px;
                        }

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }

            & ${Social} {
                order: 2;
                width: 20%;
                max-width: 228px;

                ${SocialTitle} {
                    font-size: 22px;
                    line-height: 27px;
                    text-align: left;
                }

                ${SocialLink} {
                    width: 50px;
                    height: 50px;
                }
            }

            & ${LegitScriptSocial} {
                display: flex;
            }

            & ${LegitScriptFooter} {
                display: none;
            }
        }
    }
`

function Footer() {

    return <Wrapper>
        <Container>
            <Social>
                <SocialTitle>Follow us</SocialTitle>
                <SocialLinks>
                    <SocialLink><a href="https://www.instagram.com/getcerebral/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="Instagram logo" /></a></SocialLink>
                    <SocialLink><a href="https://www.facebook.com/getcerebral/" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="Facebook logo" /></a></SocialLink>
                    <SocialLink><a href="https://www.linkedin.com/company/cerebral-inc/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="Linkedin logo" /></a></SocialLink>
                </SocialLinks>
                <LegitScriptSocial dangerouslySetInnerHTML={{
                    __html: `
                    <script src="https://static.legitscript.com/seals/4090241.js"></script>
                `}}>
                </LegitScriptSocial>
            </Social>
            <Menu>
                <MenuItem><Link to="/contact">Contact us</Link></MenuItem>
                <MenuItem><Link to="/faq">FAQ</Link></MenuItem>
                <MenuItem><Link to="/blog">Blog</Link></MenuItem>
                <MenuItem><a href="https://www.indeed.com/cmp/Cerebral" target="_blank" rel="noopener noreferrer">Careers</a></MenuItem>
            </Menu>
            <DistressLines>
                <DistresLineList>
                    <DistressLine>
                        <p>If you are in emotional distress, here are some resources for immediate help:</p>
                    </DistressLine>
                    <DistressLine>
                        <DistressText>National Suicide Prevention Hotline:</DistressText>
                        <DistressDesctiption>Call <a href="tel:1-800-273-8255">1-800-273-8255</a></DistressDesctiption>
                    </DistressLine>
                    <DistressLine>
                        <DistressText>Crisis Text Line:</DistressText>
                        <DistressDesctiption>Text Home to <a href="sms:741-741">741-741</a></DistressDesctiption>
                    </DistressLine>
                </DistresLineList>
            </DistressLines>
            <LegitScriptFooter dangerouslySetInnerHTML={{
                __html: `
                    <script src="https://static.legitscript.com/seals/4090241.js"></script>
                `}}>
            </LegitScriptFooter>
        </Container>
        <Separator />
        <Copy>
            <Rights>© 2020 Cerebral Inc. All rights reserved.</Rights>
            <Terms>
                <li><TermsLink to="/terms-and-conditions">Terms &amp; Conditions</TermsLink></li>
                |
                <li><TermsLink to="/privacy-policy">Privacy Policy</TermsLink></li>
            </Terms>
        </Copy>
    </Wrapper>
}

export default Footer