import React, { useState } from 'react'
import styled from 'styled-components'
import closeImage from '../../images/close-white.svg'

const Wrapper = styled.div`
    background: #6786FF;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

const GetStarted = styled.a`
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    cursor: pointer;
    display: block;
    text-align: center;
    text-decoration: none;
    padding: 10px 30px;
    span {
        text-decoration: underline;
    }
`

const Close = styled.div`
    position: absolute;
    width: 30px;
    height: 40px;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

function PromotionalBanner() {

    const [open, setOpen] = useState(true);

    function onCloseClick() {

        setOpen(false)
    }

    if (!open) {

        return null
    }

    return <Wrapper>
        <GetStarted href={process.env.GATSBY_START_URL}>
            <span>COVID-19 Response</span>: Only $30 for first month of remote mental health care
        </GetStarted>
        <Close onClick={onCloseClick}>
            <img src={closeImage} alt="close icon" />
        </Close>
    </Wrapper>
}

export default PromotionalBanner