import React from 'react'
import styled from 'styled-components'
// import { isRelativeUrl } from '../../lib/utils'

function Link({ className, children, to, target, rel }) {

    //TODO: update tag manager config to enable use of Gatsby link
    // if (isRelativeUrl({ to })) {

    //     return <Link to={to} className={className}>{children}</Link>
    // }

    return <a className={className} href={to} target={target} rel={rel}>{children}</a>
}

export default styled(Link)`` 