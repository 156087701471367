import styled from 'styled-components'

const Container = styled.div`
    max-width: 1140px;
    margin: 0 auto 0;
    padding: 0 12px;
    @media (min-width: 834px) {
        padding: 0 30px;
    }
`

export default Container