import styled from "styled-components";

const ItalicHeading = styled.h2`
    font-size: 28px;
    background-color: #AABAF9;
    transform:skewX(-15deg);
    color: #FFFFFF;
    padding: 6px;
    text-align: center;
    margin: 6px auto 0;
    font-weight: 600;
    width: min-content;
    white-space: nowrap;
    @media (min-width: 600px) {
        font-size: 40px;
        padding: 10px;
    }
`
export default ItalicHeading