import get from 'lodash/get'

function edgeToPostPreview({ edge }) {

    return {
        title: get(edge, 'node.data.title.text', ''),
        url: `/${edge.node.uid}`,
        previewImage: get(edge, 'node.data.previewimage.localFile.childImageSharp.fluid.src', ''),
        author_name: get(edge, 'node.data.author_name.text', ''),
        date: get(edge, 'node.data.date', ''),
    }
}

function edgeToPost({ edge }) {

    return {
        title: get(edge, 'node.data.title.text', ''),
        html: get(edge, 'node.data.content.html', ''),
        url: `/${edge.node.uid}`,
        image: get(edge, 'node.data.image.localFile.childImageSharp.fluid.src', ''),
        date: get(edge, 'node.data.date', ''),
        author_name: get(edge, 'node.data.author_name.text', ''),
        author_position: get(edge, 'node.data.author_position.text', ''),
        author_picture: get(edge, 'node.data.author_picture.localFile.childImageSharp.fixed.src', ''),
        author_description: get(edge, 'node.data.author_description.html', ''),
        author_link: get(edge, 'node.data.author_link.url', ''),
    }
}

function edgeToTestimonial({ edge }) {

    return {
        name: get(edge, 'node.data.name.text', ''),
        location: get(edge, 'node.data.location.text', ''),
        html: get(edge, 'node.data.text.html', ''),
        date: get(edge, 'node.data.date.text', ''),
        avatarNumber: get(edge, 'node.data.avatarnumber', ''),
        source: get(edge, 'node.data.source', ''),
    }
}

function edgeToProvider({ edge }) {

    return {
        name: get(edge, 'node.data.name.text', ''),
        title: get(edge, 'node.data.title.text', ''),
        profile: get(edge, 'node.data.profile.localFile.childImageSharp.fixed', ''),
        bio: get(edge, 'node.uid', ''),
    }
}

function edgeToBio({ edge }) {

    return {
        name: get(edge, 'node.data.name.text', ''),
        title: get(edge, 'node.data.title.text', ''),
        profile: get(edge, 'node.data.profile.localFile.childImageSharp.fixed.src', ''),
        bio: get(edge, 'node.data.bio.html', ''),
        quote: get(edge, 'node.data.quote.html', ''),
        education: get(edge, 'node.data.education.html', ''),
    }
}

function edgeToFaq({ edge }) {

    return {
        question: get(edge, 'node.data.question.text', ''),
        answer: get(edge, 'node.data.answer.html', ''),
        category: get(edge, 'node.data.category', ''),
        order: get(edge, 'node.data.order', ''),
    }
}

function toPage(data) {

    const { page: { edges: { 0: page } } } = data

    return {
        title: get(page, 'node.data.title.text', ''),
        contents: get(page, 'node.data.contents.html', ''),
    }
}

const r = new RegExp('^(?:[a-z]+:)?//', 'i')

function isRelativeUrl({ to }) {

    return !r.test(to)
}

export { edgeToPostPreview, edgeToPost, edgeToTestimonial, edgeToProvider, edgeToBio, edgeToFaq, isRelativeUrl, toPage }