import React, { useState } from 'react'
import styled from 'styled-components'
import Link from '../ui/Link'
import Button from './Button'
import PromotionalBanner from './PromotionalBanner'
import logoImage from '../../images/logo.svg'
import menuImage from '../../images/menu.svg'
import closeImage from '../../images/close.svg'

const Wrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: 2;
`
const Nav = styled.nav`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(165.96deg, #D2EFF6 3.32%, #D4DBF5 97.9%);
    padding: 0 15px;

    @media(min-width: 600px){
        padding: 0 30px;
    }

    @media(min-width: 834px) {
        padding: 0 50px;
    }
`

const Menu = styled.ul`
`
const MenuItem = styled.li`
`
const MenuLink = styled(Link)`
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    color: #250044;
    &:active, &:hover {
        color: #6786FF;
    }
`
const MenuLinkExternal = styled.a`
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    color: #250044;
    cursor: pointer;
    &:active, &:hover {
        color: #6786FF;
    }
`
const MobileMenuIcon = styled.div`
    display: block;
    width: 18px;
    height: 14px;
    margin: 15px 0;
    background-image: url(${menuImage});
    background-size: cover;
    cursor: pointer;
    @media(min-width: 834px) {
        display: none;
    }
`
const Logo = styled(Link)`
    width: 112px;
    height: 25px;
    background-size: cover;
    background-image: url(${logoImage});

    @media (min-width: 834px) {
        width: 178px;
        height: 40px;
    }

    img {
        height: 100%;
        width: 100%;
    }
`

const CloseButton = styled.div`
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const MobileMenu = styled.div`
    position: fixed;
    z-index: 2;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 90px;

    & > ${Logo} {
        position: absolute;
        top: 10px;
        left: 15px;
    }

    & >${CloseButton} {
        position: absolute;
        top: 2px;
        right: 3px;
    }

    & > ${Menu} {
        text-align: center;
    }

    & ${MenuItem} {
        margin: 40px;
    }

    @media(min-width: 600px) {
        
        ${Logo} {
            top: 10px;
            left: 30px;
        }
        
        ${CloseButton} {
            position: absolute;
            top: 3px;
            right: 16px;
        }
    }
`

const DesktopMenu = styled.div`
    display: none;
    & > ${Menu} {
        display: flex;
        align-items: center;
        & > li:last-child {
            margin-right: 0;
        }
    }

    & ${MenuItem} {
        margin: 20px;
    }

    @media(min-width: 834px) {
        display: block;
    }
`

const menu = <Menu>
    {/* <MenuItem><MenuLink>For Therapists</MenuLink></MenuItem> //TODO: hidden temporarily */}
    <MenuItem><MenuLink to="/faq">FAQ</MenuLink></MenuItem>
    {/* <MenuItem><MenuLink to="/blog">Blog</MenuLink></MenuItem> */}
    <MenuItem><MenuLinkExternal href={process.env.GATSBY_LOGIN_URL}>Login</MenuLinkExternal></MenuItem>
    <MenuItem><Button to={process.env.GATSBY_START_URL}>Get Started</Button></MenuItem>
</Menu>

export default function Navigation() {

    const [open, setOpen] = useState(false)

    function toggleMenu() {

        setOpen(!open)
    }

    return <>
        <Wrapper>
            <PromotionalBanner />
            <Nav>
                <Logo to="/" />
                <MobileMenuIcon onClick={toggleMenu} />
                <DesktopMenu>{menu}</DesktopMenu>
            </Nav>
        </Wrapper>
        {open &&
            <MobileMenu>
                <Logo />
                <CloseButton onClick={toggleMenu}><img src={closeImage} alt="close icon" /></CloseButton>
                {menu}
            </MobileMenu>
        }
    </>
}